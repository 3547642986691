






























































import { Component, Prop, Vue } from "vue-property-decorator";
import {
  IStrDetailValue,
  IWhatsup,
  IBaseResult,
  ISendWhatsup,
  IInfo,
  IUser,
} from "@/Services/Interfaces";
import { globalInfoService } from "@/Services/GlobalService";
import { InfoType } from "@/Services/enums";
import { dataService } from "../Services/DataServices";
import store from "../store";
import { loginViewModel } from "@/Services/LoginViewModel";
import VueRecord from "@codekraft-studio/vue-record";

@Component({
  components: {
    VueRecord,
    EmailComponent: () => import("./Base/EmailComponent.vue"),
  },
})
export default class Message extends Vue {
  @Prop()
  model: IWhatsup | undefined;

  @Prop()
  showMessage: boolean = false;

  @Prop()
  label: string | undefined;

  newmodel = <IWhatsup>{};
  thismodel: IWhatsup = <IWhatsup>{};
  user = <IUser>{};
  email = "";
  strmodel = <IStrDetailValue>{};
  cansaveit: boolean;
  recMode = "hold";
  


  constructor() {
    super();
    
    this.cansaveit = true;
  }

  /**Send audio Data */
  onResult(data: any): void {
    try {
      if (!this.model) return;
      var formData = new FormData();

      let file = new File(
        [data],
        "_" +
          (new Date().getMilliseconds() + new Date().getSeconds()) +
          "_record.mp3"
      );

      formData.append("files", file);

      var _headers = new Headers();
      _headers.append("Access-Control-Allow-Origin", "*");
      _headers.append("Accept", "application/json"),
        _headers.append(
          "Content-Type",
          "application/octet-stream, audio/mpeg, audio/ogg, audio/vnd.wav, audio/wav"
        );
      _headers.append("Access-Control-Allow-Methods", "POST");
      _headers.append(
        "Access-Control-Allow-Headers",
        "Access-Control-Request-Headers, token, Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With, to, title, text, from"
      ),
        _headers.append("token", store.getters.user.token);
      _headers.append("to", this.strmodel.strvalue);
      _headers.append("from", this.user.email);
      _headers.append(
        "title",
        this.newmodel.Titel == undefined ? "" : this.newmodel.Titel
      );
      _headers.append(
        "text",
        this.newmodel.Text == undefined ? "" : this.newmodel.Text
      );

      dataService
        .sendformData<any>("Service/uploadaudio", formData, _headers)
        .then((d) => {
          globalInfoService.sendMessage(<IInfo>{
            msg: d.message,
            error: "",
            type: InfoType.finished,
          });
        })
        .catch((ex) => {
          globalInfoService.sendMessage(<IInfo>{
            msg: "Audio upload ging wohl nicht... schade",
            error: ex,
            type: InfoType.error,
          });
        });
    } catch (e) {
      globalInfoService.sendMessage(<IInfo>{
        msg: "Audio upload Fehler .. hm",
        error: e,
        type: InfoType.error,
      });
    }
  }

  canSave(): boolean {
    if (!this.newmodel.Text && !this.newmodel.Titel) return false;
    return this.cansaveit;
  }

  mounted() {
    loginViewModel.GetUser().then((user) => {
      this.user = user;

      this.email = user.email;
      this.strmodel.strvalue = this.email;
    });
  }

  send(): void {
    if (this.newmodel == undefined) return;
    if (this.user && this.user.token) {
      this.cansaveit = false;
      globalInfoService.sendMessage(<IInfo>{
        msg: "Senden ...",
        type: InfoType.loading,
      });
      dataService.InitbyToken(store.getters.user.token);
      if (!this.thismodel.Id || this.thismodel.Id < 1) {
        this.newmodel.To = this.strmodel.strvalue;
      }
      dataService
        .post<ISendWhatsup, IBaseResult>("Service/sendwhatsup", <ISendWhatsup>{
          oldWhatsup: this.thismodel,
          newWhatsup: this.newmodel,
        })
        .then((d) => {
          this.newmodel.Titel = "";
          this.newmodel.Text = "";
          this.cansaveit = true;
          this.showMessage = false;
          this.$emit("showMessage", false);
          loginViewModel.refreshonServer(this.user).then((g) => {
            if (g.error && g.error.length > 0) {
              globalInfoService.sendMessage(<IInfo>{
                msg: g.message,
                error: g.error,
                type: InfoType.warning,
              });
            } else {
              globalInfoService.sendMessage(<IInfo>{
                msg: '',
                error: "",
                type: InfoType.finished,
              });
            }
          });
        })
        .catch((ex) => {
          this.cansaveit = true;
          globalInfoService.sendMessage(<IInfo>{
            msg: "Senden ging wohl nicht... schade",
            error: ex,
            type: InfoType.error,
          });
        });
    }
  }

  dynRows(): number {
    if (this.newmodel == undefined) return 2;
    if (this.newmodel.Text == undefined) return 3;
    if (this.newmodel.Text.length < 50) return 4;
    if (this.newmodel.Text.length < 100) return 5;
    if (this.newmodel.Text.length < 1000) return 10;
    if (this.newmodel.Text.length < 3000) return 20;
    return 30;
  }

  data() {
    return {
      thismodel: this.model,
      newmodel: <IWhatsup>{},
      cansaveit: true,
      strmodel: <IStrDetailValue>{
        id: -1,
        fieldname: "To",
        typname: "Whatsup",
        crud: "create",
        strvalue: "",
        inputtype: "email",
        valuetyp: "string",
      },
      email: "",
    };
  }

  computed() {}
}
